import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { motion } from "framer-motion";

const NavClickArea = styled(motion.div)`
  position: fixed;
  height: 58px;
  width: 50px;
  top: 0;
  right: 0;
  color: #fff;
  border-bottom-left-radius: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 65px;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    fill: #fff;

    &:hover {
      cursor: pointer;
    }
  }

  span {
    margin-top: 2px;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      margin-top: 5px;
    }
  }
`;
const Line = props => (
  <motion.line fill="none" stroke="#fcf7f7" strokeWidth={3} {...props} />
);
const NavButton = ({ isOpen, setIsOpen, label, backgroundColor, icon }) => {
  const theme = useTheme();
  const navClickVariants = {
    closed: {
      backgroundColor: backgroundColor,
      // transitionEnd: {
      //   backgroundColor: backgroundColor,
      // },
    },
    open: {
      backgroundColor: theme.colors.darkRed,
    },
  };
  return (
    <NavClickArea
      onClick={() => setIsOpen(!isOpen)}
      variants={navClickVariants}
    >
      <span>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          variants={{
            closed: { width: "15", height: "16" },
            open: { width: "16", height: "16" },
          }}
          viewBox={isOpen ? "0 0 16 16" : "0 0 15 16"}
        >
          <motion.g
            variants={{
              closed: { x: 0, y: 1.5 },
              open: { y: 0 },
            }}
          >
            <Line
              variants={{
                closed: { y1: 0, x2: 15 },
                open: { y1: 16, x2: 16 },
              }}
            />
            <Line
              x2="11.563"
              opacity={1}
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              style={{ x: 3, y: 6.5 }}
            />
            <Line
              variants={{
                closed: { x2: 8.563, x: 6, y: 13, y1: 0, y2: 0 },
                open: { x2: 16, y1: 0, x: 0, y: 0, y2: 16 },
              }}
            />
          </motion.g>
        </motion.svg>
      </span>
    </NavClickArea>
  );
};

export default NavButton;
