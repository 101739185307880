import React, { useContext } from "react";
import PageTransitionContext from "../../contexts/PageTransitionContext";
import TransitionLink from "gatsby-plugin-transition-link";

const PageLink = ({ to, children, ...props }) => {
  const { triggerPageTransition } = useContext(PageTransitionContext);
  return (
    <TransitionLink
      {...props}
      to={to}
      exit={{ length: 1, trigger: () => triggerPageTransition() }}
      entry={{
        appearAfter: 0.35,
      }}
    >
      {children}
    </TransitionLink>
  );
};

export default PageLink;
