export default {
  colors: {
    darkRed: "#E81821",
    lightRed: "#FF2525",
    lighterGold: "#E3AD92",
    lightGold: "#DFAC92",
    darkGold: "#D38B5C",
    darkestPurple: "#1B104E",
    darkerPurple: "#3C02AA",
    lighterPurple: "#5344E0",
    lightestPurple: "#666CFD",
    titlePurple: "#4514C1",
    darkPink: "#D572AB",
    mediumPink: "#EA90C4",
    lightPink: "#FFA3D3",
    darkTeal: "#3FADAC",
    mediumTeal: "#56C4C3",
    mediumLightTeal: "#88E3DC",
    lightTeal: "#73DDD3",
    offBlack: "#1A1A1A",
    black: "#000000",
    grey: "#575757",
    darkPeach: "#EAD6CE",
    lightPeach: "#F4EDEB",
    offWhite: "#FCF7F7",
    white: "#FFFFFF",
    green: "#61A744",
  },
  breakpoints: {
    xs: "576px",
    sm: "768px",
    md: "1024px",
    lg: "1366px",
    xl: "1650px",
  },
  breakpointNumbers: {
    xs: 576,
    sm: 768,
    md: 1024,
    lg: 1366,
    xl: 1650,
  },
  fonts: {
    regular: `"GT America", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    medium: `"GT America Medium", "GT America", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    light: `"GT America Light", "GT America", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    condensedMedium: `"GT America Condensed Medium", "GT America", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    italic: `"GT America Regular Italic", "GT America", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
  },
  fontsNoQuotes: {
    regular: `GT America, Helvetica Neue, Helvetica, Arial, sans-serif`,
    medium: `GT America Medium, GT America, Helvetica Neue, Helvetica, Arial, sans-serif`,
    light: `GT America Light, GT America, Helvetica Neue, Helvetica, Arial, sans-serif`,
    condensedMedium: `GT America Condensed Medium, GT America, Helvetica Neue, Helvetica, Arial, sans-serif`,
    italic: `GT America Regular Italic, GT America, Helvetica Neue, Helvetica, Arial, sans-serif`,
  },
};
