import React from "react";
import PropTypes from "prop-types";
import PreFooterCta from "./PreFooterCta";
import PreFooterJobs from "./PreFooterJobs";

const JOBS = "jobs";
const CTA = "cta";

const PreFooter = ({ data }) => {
  // console.log(data?.prefooterPremade);
  // function to render a certain prefooter depending on the layout specified from the backend
  const renderPreFooter = layout => {
    switch (layout) {
      case CTA:
        return (
          <PreFooterCta data={data?.prefooterPremade?.PreFooterSingle?.cta} />
        );
      case JOBS:
        return (
          <PreFooterJobs data={data?.prefooterPremade?.PreFooterSingle?.jobs} />
        );
      default:
        return null;
    }
  };

  // render the prefooter only if active is true and if there's a selected PreFooter
  return data?.active && data?.prefooterPremade
    ? renderPreFooter(data?.prefooterPremade?.PreFooterSingle.layout)
    : null;
};

PreFooter.propTypes = {
  data: PropTypes.shape({
    active: PropTypes.bool, // whether the prefooter is active or not
    fieldGroupName: PropTypes.string,
    prefooterPremade: PropTypes.shape({
      PreFooterSingle: PropTypes.shape({
        fieldGroupName: PropTypes.string,
        layout: PropTypes.string.isRequired,
        cta: PropTypes.object,
        jobs: PropTypes.object,
      }),
    }),
  }),
};

export default PreFooter;
