import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import BrandishLogoSrc from "../icons/BrandishLogo.svg";
import PageLink from "./PageLink";
import BrandishHeaderLogo from "../icons/brandish-header-logo.svg";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// styled navigation
const Nav = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.colors.darkRed};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  z-index: 100;
  align-items: flex-start;
  overflow-y: auto;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem 2rem 3rem 3rem;
  }
`;
// navigation component holding title and toggle section
const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.lightPeach};
  font-family: ${props => props.theme.fonts.regular};
  font-size: 2.1rem;
  width: 100%;
  .brandish-logo {
    width: 130px;
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
    }
  }
  h2 {
    margin: 0;
    font-size: 3.5rem;
    font-family: ${props => props.theme.fonts.regular};

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 4.5rem;
    }
  }

  p {
    font-size: 2.1rem;
    margin: 0 6rem 0 0;
  }
`;
// styled navigation list
const NavList = styled.ul`
  margin: 0 0 0 0;
  width: 100%;
  list-style: none;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 65%;
  }
`;
// styled navigation item
const NavItem = styled.li`
  margin-top: 2rem;
`;

// styled navigation title link
// const NavTitle = styled(PageLink)`
//   text-decoration: none;
//   color: ${props => props.theme.colors.lightPeach};
// `;

// styled navigation links
const NavLink = styled(PageLink)`
  color: ${props => props.theme.colors.lightPeach};
  font-family: ${props => props.theme.fonts.regular};
  text-decoration: none;
  font-size: 3.5rem;

  &:hover {
    border-bottom: 2px solid #f4edeb;
  }

  &.activeMenuLink {
    // font-family: ${props => props.theme.fonts.medium};
    border-bottom: 4px solid #f4edeb;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 4.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 5.5rem;
  }
`;

const BrandishLogoLink = styled(PageLink)``;

// styled navigation brandish logo
const BrandishLogo = styled(BrandishLogoSrc)`
  color: ${props => props.theme.colors.lightPeach};
`;

const Navigation = ({ isOpen, setIsOpen }) => {
  // GraphQL query for Navigation Menu Items
  const data = useStaticQuery(menuQuery);

  const menuItems = data.wpMenu.menuItems.nodes;

  const handleCurrentPageClick = path => {
    if (typeof window !== "undefined" && window.location.pathname === path) {
      setIsOpen(!isOpen);
      gsap.to(window, { scrollTo: 0, duration: 0 });
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollToPlugin);
    }
  }, []);
  return (
    <Nav id="main-nav-menu">
      <NavBar>
        {/* <NavTitle to="/">
          <h2>Brandish</h2>
        </NavTitle> */}
        <PageLink to="/" className="nav-logo">
          <BrandishHeaderLogo className="brandish-logo" />
        </PageLink>
        <p>Menu</p>
      </NavBar>
      <NavList>
        {menuItems.map(item => (
          <NavItem key={item.databaseId}>
            <NavLink
              activeClassName="activeMenuLink"
              to={item.url}
              onClick={() => handleCurrentPageClick(item.url)}
            >
              {item.label}
            </NavLink>
          </NavItem>
        ))}
      </NavList>
      <BrandishLogoLink to="/" onClick={() => setIsOpen(!isOpen)}>
        <BrandishLogo />
      </BrandishLogoLink>
    </Nav>
  );
};

const menuQuery = graphql`
  query {
    wpMenu(databaseId: { eq: 2 }) {
      menuItems {
        nodes {
          label
          target
          title
          url
          databaseId
          cssClasses
        }
      }
    }
  }
`;
export default Navigation;
