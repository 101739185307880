import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

import Nav from "./navigation";
import NavButton from "./navButton";
import ContentContext from "../../contexts/ContentContext";
import { useTheme } from "@emotion/react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { useWindowHeight, useWindowWidth } from "../shared/hooks";

// Nagivation control component\
// const NavToggle = styled.div`
//   position: absolute;
//   width: 100%;
//   color: #fff;
//   padding: 1rem;
//   font-size: 2.1rem;
//   font-family: ${props => props.theme.fonts.regular};
//   display: flex;
//   justify-content: space-between;
//   color: #333;

//   h2 {
//     margin: 0;
//     font-size: 4rem;

//     @media (min-width: ${props => props.theme.breakpoints.sm}) {
//       font-size: 4.5rem;
//     }
//   }

//   @media (min-width: ${props => props.theme.breakpoints.sm}) {
//     padding: 2.5rem;
//   }
// `;

// styled navigation title link
// const NavTitle = styled(Link)`
//   text-decoration: none;
//   color: ${props => props.theme.colors.lightPeach};
// `;

// styled header
// const HeaderContainer = styled.header`
//   padding: 1rem;
//   color: ${props => props.theme.colors.lightPeach};
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-family: ${props => props.theme.fonts.regular};

//   @media (min-width: ${props => props.theme.breakpoints.sm}) {
//     padding: 1rem 2rem;
//   }

//   h2 {
//     font-size: 3.5rem;
//     margin: 0;
//     font-family: ${props => props.theme.fonts.regular};

//     @media (min-width: ${props => props.theme.breakpoints.sm}) {
//       font-size: 4.5rem;
//     }
//   }

//   p {
//     font-size: 2.1rem;
//     margin: 0 40px 0 0;
//   }
// `;

// styled navigation header
const HeaderComponent = styled.header`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  height: 100vh;
  font-family: ${props => props.theme.fonts.regular};
`;

const HeaderComponentWrapper = styled(motion.div)`
  position: fixed;
  right: 0;
  z-index: 100;
`;

// function to calculate pythagorean theorem
const pyth = (a, b) => Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

const Header = () => {
  const windowHeight = useWindowHeight();
  const windowWidth = useWindowWidth();
  const [isOpen, setIsOpen] = useState(false);
  const [clipRadius, setClipRadius] = useState(0);
  const headerRef = useRef(null);
  const content = useContext(ContentContext);
  const theme = useTheme();

  useEffect(() => {
    isOpen
      ? disableBodyScroll(document.querySelector("#main-nav-menu"))
      : clearAllBodyScrollLocks();
  }, [isOpen]);

  useEffect(() => {
    // recalculate clip-path circle radius and set into state and onto the clip path directly whenever window is resized
    const radius = pyth(windowWidth, windowHeight);
    setClipRadius(radius);
    headerRef.current.style.clipPath = `circle(${radius}px at 100% 0%)`;
  }, [headerRef, windowWidth, windowHeight]);
  // Variants for navigation control
  const variants = {
    open: (radius = clipRadius) => ({
      clipPath: `circle(${radius}px at 100% 0%)`,
      visibility: "visible",
      transition: {
        type: "tween",
        // stiffness: 50,
        // restDelta: 2,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
    closed: {
      clipPath: `circle(0px at 100% 0%)`,
      transition: {
        // delay: 0.5,
        type: "spring",
        stiffness: 35,
        restDelta: 2,
        // damping: 40,
      },
      transitionEnd: {
        visibility: "hidden",
      },
    },
  };
  return (
    <motion.div initial={false} animate={isOpen ? "open" : "closed"}>
      {!content?.PageCptFields?.hideNavIcon && (
        <NavButton
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          label="Menu"
          backgroundColor={
            content.HeroSection?.hamburgerBackgroundColor ||
            theme.colors.darkRed
          }
        />
      )}
      {/* </HeaderContainer> */}
      <HeaderComponentWrapper
        ref={headerRef}
        initial={false}
        variants={variants}
      >
        <HeaderComponent>
          <Nav isOpen={isOpen} setIsOpen={setIsOpen} />
        </HeaderComponent>
      </HeaderComponentWrapper>
    </motion.div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
