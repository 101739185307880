import React from "react";
import styled from "@emotion/styled";
import Image from "../image";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import CtaButton from "../CtaButton";
import PageLink from "../PageLink";
import InnerContainer from "../InnerContainer";

const Container = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  padding: 4rem 1.5rem 5.5rem;
  background-color: ${props => props.theme.colors.lightGold};
  position: relative;
  z-index: 1;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 6rem;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
    /* background-color: red; */
    justify-content: space-between;
    /* grid-template-columns: repeat(3, 1fr); */
  }

  // .row-margin {
  //   @media (min-width: ${props => props.theme.breakpoints.md}) {
  //     margin-right: 50px;
  //   }
  //   @media (min-width: ${props => props.theme.breakpoints.lg}) {
  //     margin-right: 80px;
  //   }
  // }
`;

const Title = styled.h3`
  font-family: ${props => props.theme.fonts.medium};
  text-align: center;
  font-size: 3.1rem;
  line-height: 38px;
  margin: 3rem 0 4rem;
  width: auto;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 310px;
    margin: 0;
    padding: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    /* text-align: left; */
    /* width: 55.21875%; */
    font-size: 4.8rem;
    line-height: 60px;
    max-width: 600px;
  }
`;

const CtaImage = styled(Image)`
  /* margin: auto; */
  /* flex-basis: 0; */
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 0;
    padding: 0;
    max-height: 215px;
    /* width: 25.78125%; */
    /* margin: 0; */
    /* width: 26.034482758%; */
  }
`;

const CtaContainer = styled.div`
  width: 50%;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 30%;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    max-width: 220px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 280px;
  }
`;

const PreFooterCta = ({ data }) => {
  // console.log(data);
  return (
    <Container>
      <InnerContainer>
        <Row>
          {data.prefooterImageLink ? (
            <CtaContainer className="row-margin">
              <PageLink to={data.prefooterImageLink.url}>
                <CtaImage image={data.prefooterImage} />
              </PageLink>
            </CtaContainer>
          ) : (
            <CtaContainer className="row-margin">
              <CtaImage image={data.prefooterImage} />
            </CtaContainer>
          )}
          <Title
            className="row-margin"
            css={css`
              color: ${data.prefooterTitleColor};
            `}
          >
            {data.prefooterTitle}
          </Title>
          <CtaButton
            linkType={data.buttonLinkType}
            email={data.buttonEmail}
            text={data.buttonText}
            type={data.buttonType}
            color={data.buttonColor}
            link={data.buttonLink}
            css={css`
              width: auto !important;
            `}
          />
        </Row>
      </InnerContainer>
    </Container>
  );
};

PreFooterCta.propTypes = {
  data: PropTypes.shape({
    buttonLinkType: PropTypes.oneOf(["page", "email"]),
    buttonEmail: PropTypes.string,
    buttonText: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonType: PropTypes.oneOf(["solid", "white"]),
    buttonLink: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    fieldGroupName: PropTypes.string,
    prefooterTitle: PropTypes.string,
    prefooterTitleColor: PropTypes.string,
    prefooterImage: PropTypes.object,
    prefooterImageLink: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default PreFooterCta;
