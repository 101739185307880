import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { css, useTheme } from "@emotion/react";
import JobCard from "../JobCard";
import CtaButton from "../CtaButton";
import InnerContainer from "../InnerContainer";

const Container = styled.div`
  background-color: ${props => props.theme.colors.lightGold};
  padding: 4rem 1.5rem;
  position: relative;
  z-index: 1;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 6rem 7rem;
  }

  .opportunity-button {
    text-align: center;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      text-align: left;
      padding-left: 1rem;
    }
  }
`;
const Title = styled.h3`
  font-family: ${props => props.theme.fonts.medium};
  font-weight: 500;
  font-size: 3.1rem;
  line-height: 38px;
  text-align: center;
  margin-bottom: 3.5rem;
  color: ${props => props.theme.colors.lightPeach};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 4.8rem;
    line-height: 60px;
    margin-bottom: 6rem;
    text-align: left;
    padding: 0 1rem;
  }
`;

const Subtitle = styled.h4`
  font-size: 2.3rem;
  line-height: 35px;
  margin: 3rem;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    text-align: left;
    margin: 0 0 5rem 0;
    padding: 0 1rem;
  }
`;

const PreFooterJobs = ({ data }) => {
  const theme = useTheme();
  const JobListingContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      /* grid-column-gap: 2rem; */
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      margin-top: 6rem;
      margin-bottom: 7rem;
    }
  `;
  // console.log(data);
  return (
    <Container className="container-padding">
      <InnerContainer>
        {data.title && (
          <Title
            css={css`
              color: ${theme.colors.lightPeach};
            `}
          >
            {data.title}
          </Title>
        )}
        <JobListingContainer>
          {data.jobItems.map(
            item =>
              item.jobItem && (
                <JobCard key={item.jobItem.databaseId} job={item.jobItem} />
              )
          )}
        </JobListingContainer>
        {data.subtitle && (
          <Subtitle
            css={css`
              color: ${theme.colors.lightPeach};
            `}
          >
            {data.subtitle}
          </Subtitle>
        )}
        <div className="opportunity-button">
          <CtaButton
            linkType={data.buttonLinkType}
            email={data.buttonEmail}
            text={data.buttonText}
            type={data.buttonType}
            color={data.buttonColor}
            link={data.buttonLink}
          />
        </div>
      </InnerContainer>
    </Container>
  );
};

PreFooterJobs.propTypes = {
  data: PropTypes.shape({
    buttonLinkType: PropTypes.oneOf(["page", "email"]),
    buttonEmail: PropTypes.string,
    buttonText: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonType: PropTypes.oneOf(["solid", "white"]),
    buttonLink: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    fieldGroupName: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    jobItems: PropTypes.array,
  }),
};

export default PreFooterJobs;
