/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "@emotion/react";

import Header from "./header";
import Footer from "./footer";
// import "tailwindcss/dist/base.min.css";
import "../scss/base.scss";

import theme from "../shared/theme";
import ContentContext from "../../contexts/ContentContext";
import PreFooter from "./prefooters/PreFooter";
import Seo from "./seo";
import { TransitionPortal } from "gatsby-plugin-transition-link";
import styled from "@emotion/styled";
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { PageTransitionProvider } from "../../contexts/PageTransitionContext";

gsap.registerPlugin(CSSPlugin);

const TransitionCover = styled.div`
  position: fixed;
  background: ${props => props.theme.colors.lightPeach};
  top: 50%;
  left: 100%;
  width: 200vh;
  height: 200vh;
  transform: translateY(-50%);
  border-radius: 50%;
`;

const Layout = ({ children }) => {
  const transitionRef = useRef(null);
  const triggerPageTransition = () => {
    return gsap.timeline().to(transitionRef.current, {
      left: "-100%",
      duration: 1,
      ease: "power1.inOut",
    });
  };
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);
  const context = useContext(ContentContext);
  return (
    <>
      <Seo seo={context.seo} />
      <ThemeProvider theme={theme}>
        <PageTransitionProvider value={{ triggerPageTransition }}>
          <Header />
          <main>{children}</main>
          <PreFooter data={context?.PreFooter} />
          <Footer />
          <TransitionPortal>
            <TransitionCover ref={transitionRef} />
          </TransitionPortal>
        </PageTransitionProvider>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
