import { useState, useLayoutEffect, useEffect } from "react";

/**
 * Returns an object with width and height of window as properties
 */
export const useWindowSize = () => {
  const isClient = typeof window !== "undefined";
  const [windowSize, setWindowSize] = useState({
    width: isClient && window.innerWidth,
    height: isClient && window.innerHeight,
  });

  useLayoutEffect((): any => {
    const handleResize = () => setWindowSize({
      width: isClient && window.innerWidth,
      height: isClient && window.innerHeight,
    });
    isClient && window.addEventListener("resize", handleResize);
    return () => {
      isClient && window.removeEventListener("resize", handleResize);
    }
  });
  return windowSize;
};

/** 
 * Returns the width of the window as an integer if the window object exists
 * @returns {number} width of the window object
 */
export const useWindowWidth = () => {
  const isClient = typeof window !== "undefined";
  const [windowWidth, setWindowWidth] = useState(isClient && window.innerWidth);
  useLayoutEffect((): any => {
    const handleResize = () => setWindowWidth(isClient && window.innerWidth);
    isClient && window.addEventListener("resize", handleResize);
    return () => {
      isClient && window.removeEventListener("resize", handleResize);
    }
  });
  return windowWidth;
};

/**
 * Returns the height of the window as an integer if the window object exists
 * @returns {number} height of the window object
 */
export const useWindowHeight = () => {
  const isClient = typeof window !== "undefined";
  const [windowHeight, setWindowHeight] = useState(isClient && window.innerHeight);
  useLayoutEffect((): any => {
    const handleResize = () => setWindowHeight(isClient && window.innerHeight);
    isClient && window.addEventListener("resize", handleResize);
    return () => {
      isClient && window.removeEventListener("resize", handleResize);
    }
  });
  return windowHeight;
};

/**
 * Returns window vertical scroll position
 * @returns {number} Y scroll position
 */
export const useWindowScrollPositionVertical = () => {
  const isClient = typeof window !== 'undefined';
  const [scrollPositionY, setScrollPositionY] = useState(isClient && window.scrollY);
  useEffect(() => {
    const handleScroll = () => setScrollPositionY(isClient && window.scrollY);
    isClient && window.addEventListener('scroll', handleScroll);
    return () => {
      isClient && window.removeEventListener('scroll', handleScroll);
    }
  });
  return scrollPositionY;
}
