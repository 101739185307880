/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import ContentContext from "../../contexts/ContentContext";

function SEO({ seo }) {
  const data = useStaticQuery(
    graphql`
      query {
        wp {
          seo {
            social {
              facebook {
                defaultImage {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const context = useContext(ContentContext);
  return seo ? (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={`${seo.title}` || `${context.title}`}
      meta={[
        {
          name: `robots`,
          content: `${seo.metaRobotsNoindex} ${seo.metaRobotsNofollow}`,
        },
        {
          name: `description`,
          content: seo.metaDesc,
        },
        {
          name: `keywords`,
          content: seo.metaKeywords,
        },
        {
          property: `og:title`,
          content: seo.opengraphTitle || seo.title || `${context.title}`,
        },
        {
          property: `og:description`,
          content: seo.opengraphDescription || seo.metaDesc,
        },
        {
          property: `og:image`,
          content:
            seo.opengraphImage?.localFile?.publicURL ||
            data?.wp?.seo?.social?.facebook?.defaultImage?.localFile?.publicURL,
        },
        {
          property: `og:type`,
          content: seo.opengraphType || `website`,
        },
        {
          property: `og:url`,
          content: seo.opengraphUrl,
        },
        {
          property: `og:site_name`,
          content: seo.opengraphSiteName,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `Brandish Agency`,
        },
        {
          name: `twitter:title`,
          content:
            seo.twitterTitle ||
            seo.opengraphTitle ||
            seo.title ||
            `${context.title}`,
        },
        {
          name: `twitter:description`,
          content:
            seo.twitterDescription || seo.opengraphDescription || seo.metaDesc,
        },
        {
          name: `twitter:image`,
          content:
            seo.opengraphImage?.localFile?.publicURL ||
            data?.wp?.seo?.social?.facebook?.defaultImage?.localFile?.publicURL,
        },
      ]}
    >
      <script type="application/ld+json">{seo?.schema?.raw}</script>
    </Helmet>
  ) : null;
}

SEO.propTypes = {
  seo: PropTypes.object.isRequired,
};

export default SEO;
