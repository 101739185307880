import React from "react";
import styled from "@emotion/styled";

import Logo from "../icons/BrandishLogoLarge.svg";

import {
  FaLinkedin,
  FaInstagram,
  FaFacebookF,
  FaBehance,
  FaRegCopyright,
} from "react-icons/fa";
import { useStaticQuery, graphql } from "gatsby";
import PageLink from "./PageLink";

const FooterComponent = styled.footer`
  position: relative;
  background-color: #d38b5c;
  padding: 1.5rem;
  color: #f4edeb;
  text-transform: uppercase;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 1rem 6rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: 232px;
  }
`;

const InfoContainer = styled.div`
  margin-top: 4rem;
  font-family: ${props => props.theme.fonts.condensedMedium};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    /* margin: 9.3rem 0 6rem 0; */
    margin-top: 8rem;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // max-width: 850px;
    /* justify-content: space-between; */
    align-items: center;
  }

  ul {
    display: flex;
    width: auto;
    margin: 0 0 4rem 0;
    list-style: none;
    padding-left: 0;
    /* justify-content: space-between; */

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      /* margin: 0; */
      margin-right: 5rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0;
      margin-right: 8rem;
    }

    li {
      margin: 0;
      &:not(:last-child) {
        margin-right: 3rem;
      }
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    letter-spacing: 2.25px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 0;
      margin-right: 5rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-right: 8rem;
    }

    span {
      color: #ff2525;
    }
  }
`;

const CopyrightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  min-height: 250px;
  font-family: ${props => props.theme.fonts.condensedMedium};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    min-height: 80px;
  }

  p {
    width: 50%;
    font-size: 1.3rem;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
  }
`;

const BrandishLogoFooter = styled(Logo)`
  position: absolute;
  height: 300px;
  width: 300px;
  right: -60px;
  bottom: 0;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 225px;
    width: 225px;
    right: 0;
  }

  path {
    fill: #dfac92;
  }
`;

const FooterMenu = styled.p``;

const FooterMenuLink = styled(PageLink)`
  display: block;
`;

const FACEBOOK = "facebook";
const LINKEDIN = "linkedin";
const BEHANCE = "behance";
const INSTAGRAM = "instagram";

const Footer = () => {
  // destructured SocialLinks out of the nested ACF options page query
  const {
    wp: {
      socialLinks: { SocialLinks },
    },
    wpMenu,
  } = useStaticQuery(footerQuery);
  const { email, socialLinks } = SocialLinks;
  const footerMenuItems = wpMenu.menuItems.nodes;
  const thisYear = new Date().getFullYear();

  const renderSocialIcon = brand => {
    switch (brand) {
      case FACEBOOK:
        return <FaFacebookF />;
      case LINKEDIN:
        return <FaLinkedin />;
      case INSTAGRAM:
        return <FaInstagram />;
      case BEHANCE:
        return <FaBehance />;
      default:
        return null;
    }
  };

  const socialLinksList = socialLinks?.map((link, index) => (
    <li key={index}>
      <a href={link.link} target="_blank" rel="noopener noreferrer">
        {renderSocialIcon(link.brand)}
      </a>
    </li>
  ));

  const footerMenuLinksList = footerMenuItems?.map(link => (
    <FooterMenuLink key={link?.databaseId} to={link?.url}>
      {link?.label}
    </FooterMenuLink>
  ));

  return (
    <FooterComponent>
      <InfoContainer>
        <ul>{socialLinksList}</ul>
        <FooterMenu>{footerMenuLinksList}</FooterMenu>
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p>
          winnipeg <span>&mdash;</span> toronto
        </p>
      </InfoContainer>
      <CopyrightContainer>
        <p>
          <FaRegCopyright />{" "}
          <span style={{ marginLeft: 5 }}>brandish {thisYear}</span>
        </p>
        <BrandishLogoFooter />
      </CopyrightContainer>
    </FooterComponent>
  );
};

const footerQuery = graphql`
  query {
    wp {
      socialLinks {
        SocialLinks {
          email
          socialLinks {
            brand
            link
          }
        }
      }
    }
    wpMenu(databaseId: { eq: 381 }) {
      menuItems {
        nodes {
          label
          target
          title
          url
          databaseId
          cssClasses
        }
      }
    }
  }
`;
export default Footer;
