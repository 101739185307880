import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
// import { useTheme } from "@emotion/react";

/*
 * This component is built using `gatsby-plugin-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes.
 */

const Image = ({ image, ...props }) => {
  // const theme = useTheme();
  // console.log(props);
  // console.log(image);
  // check if there is a file, otherwise return null
  // if there is a file, check if it has a childImageSharp property. If present, it's a valid image to use with Gatsby Image. If not, it's invalid gatsby-image ex. .gif, .svg, so we just return a regular img tag.
  return image?.localFile ? (
    image.localFile.childImageSharp ? (
      <GatsbyImage
        image={image.localFile.childImageSharp.gatsbyImageData}
        backgroundColor={props.backgroundColor || `transparent`}
        alt={image.altText}
        {...props}
      />
    ) : (
      <img {...props} src={image.localFile.publicURL} alt={image.altText} />
    )
  ) : // Todo: take out image check and just return null if image is not a localfile
  image ? (
    React.cloneElement(image, props)
  ) : null;
};

Image.propTypes = {
  image: PropTypes.shape({
    databaseId: PropTypes.number,
    altText: PropTypes.string,
    localFile: PropTypes.shape({
      extension: PropTypes.string,
      publicURL: PropTypes.string,
      relativePath: PropTypes.string,
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }).isRequired,
};

export default Image;
