import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import PageLink from "./PageLink";

const Card = styled.div`
  padding: 2.4rem 3.4rem;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.lightPeach};
  width: 100%;
  margin-bottom: 4rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin: 1rem;
    width: calc(50% - 2rem);
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    width: calc(33% - 2rem);
    padding: 3.5rem 3.4rem;
  }
`;

const JobTitle = styled.h3`
  font-size: 3.1rem;
  line-height: 38px;
  margin-bottom: 3.5rem;
  min-height: 7.6rem;
  color: ${props => props.theme.colors.darkGold};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    min-height: 11.4rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    min-height: 7.6rem;
  }
`;

const JobIntro = styled.p`
  font-size: 1.8rem;
  line-height: 29px;
  margin-bottom: 2rem;
  min-height: 8.7rem;
  color: ${props => props.theme.colors.offBlack};
`;

const JobLink = styled(PageLink)`
  font-family: ${props => props.theme.fonts.condensedMedium};
  font-size: 1.8rem;
  line-height: 25px;
  letter-spacing: 2.7px;
  color: ${props => props.theme.colors.lighterPurple};
  text-transform: uppercase;
  text-decoration: underline;
`;

const JobCard = ({ job }) => {
  return (
    <Card>
      <JobTitle>
        <PageLink to={job.uri}>{job.title}</PageLink>
      </JobTitle>
      <JobIntro>{job.JobPosting.intro}</JobIntro>
      <JobLink to={job.uri}>See Details</JobLink>
    </Card>
  );
};

JobCard.propTypes = {
  job: PropTypes.shape({
    databaseId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    JobPosting: PropTypes.shape({
      fieldGroupName: PropTypes.string,
      intro: PropTypes.string,
    }),
  }),
};
export default JobCard;
