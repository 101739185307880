import React from "react";
import styled from "@emotion/styled";

const InnerContainerDiv = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 1260px;
  }
`;

// making sure to pass in the children to render and to add any props that might
// be passed to it like custom styling for this container
const InnerContainer = ({ children, ...props }) => {
  return <InnerContainerDiv {...props}>{children}</InnerContainerDiv>;
};

export default InnerContainer;
